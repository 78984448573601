@import '../abstracts/variables';
@import '../abstracts/mixins';

#kinderschutzsiegel, #article-463 {
    padding: 1rem;
    margin: 1rem -1rem 0 -1rem;

    display: flex;
    flex-wrap: wrap;

    &>.block {
        flex: 0 0 50%;
    }

    .ce_text {
        margin: 1rem -1rem 0 -1rem;
        flex: 0 0 calc(100% + 2rem);
        background-color: $primaryColor;
        color: $white;
        font-weight: bold;
        p {
            padding: 1rem;
            margin-bottom: 0;
            line-height: 1.75rem;
        }
    }

    .kinderschutzsiegel, .jugendschutzpraedikat {
        text-align: center;
    }
}
